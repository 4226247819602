import React from "react";
import { createStandaloneToast } from "@chakra-ui/react"

function Toast(message, type) {
  const toast = createStandaloneToast()
  return toast({
      position: "top",
      description: message,
      status: type,
      duration: 5000,
      isClosable: true,
    })
}

export default Toast;