import { Box, Flex, Image, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import logoWhite from "../assets/images/logoWhite.svg";
import s1 from "../assets/images/s1.png";
import s2 from "../assets/images/s2.png";
import s3 from "../assets/images/s3.png";
import s4 from "../assets/images/s4.png";
import location from "../assets/images/location.svg";

export const socials = [
    {
        name: "Twitter",
        url: "https://x.com/zinewallet",
        image: s1,
    },
    {
        name: "Facebook",
        url: "https://www.facebook.com/profile.php?id=61566906915063&mibextid=LQQJ4d",
        image: s2,
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/tradecryptowithzine",
        image: s3,
    },
    {
        name: "LinkedIn",
        url: "https://www.linkedin.com/company/zinewallet/",
        image: s4,
    },
];

export default function Footer({ lang }) {
    const history = useHistory();
    const menu2 = [
        lang === "fr" ? "À propos de nous" : "About us",
        "Contact",
        lang === "fr" ? "politique de confidentialité" : "Privacy policy",
        lang === "fr" ? "Politique AML" : "AML Policy",
        lang === "fr" ? "Termes et conditions" : "Terms and Conditions",
    ];

    return (
        <Flex w="100%" justify="center" bg="#4E0DD9" color="#fff">
            <Flex className="container" px={["5", "3%"]}>
                <Flex w="100%" py={["40px", "80px"]} direction="column">
                    <Flex w="100%" justify="space-between" align="center">
                        <Flex
                            cursor="pointer"
                            onClick={() => {
                                history.push("/");
                                return window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <Image w={["74px", "101.33px"]} src={logoWhite} />
                        </Flex>
                        <Flex gap="6" align="end" direction="column">
                            <Flex w={["70%", "auto"]} gap="1" fontSize={["13px", "16px"]} align="end" justify="end">
                                <Image
                                    cursor="pointer"
                                    h={["17px", "19.96px"]}
                                    src={location}
                                    mb="4px"
                                    alt="location"
                                />
                                {lang === "fr"
                                    ? "No. 2 Opefia Close, près de Mobil Estate Road, Lekki-Epe, Lagos."
                                    : "No. 2 Opefia Close, off Mobil Estate Road, Lekki-Epe, Lagos."}
                            </Flex>

                            <Flex>
                                {socials.map((social, index) => (
                                    <a href={social.url} key={index} rel="noopener noreferrer" target="_blank">
                                        <Image
                                            alt={social.name}
                                            cursor="pointer"
                                            h="19.96px"
                                            src={social.image}
                                            mr={[index !== 3 && "4", index !== 3 && "6"]}
                                        />
                                    </a>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex w="100%" bg="#7F48F4" h="1px" my={["24px", "60px"]}></Flex>
                    <Flex w="100%" justify="space-between" align="center">
                        <Text w={["400px", "auto"]} mr={["4", "0"]} fontSize={["13px", "15px"]}>
                            {lang === "fr"
                                ? "Portefeuille Zine @ 2024. Tous droits réservés."
                                : "Zine Wallet @ 2024. All rights reserved."}
                        </Text>
                        <Flex gap="5" align="end" direction="column">
                            <Wrap
                                w={["auto", "70%", "80%"]}
                                align="end"
                                justify="end"
                                fontSize={["13px", "16px"]}
                                spacing={["10px", "20px"]}
                            >
                                {menu2.map((item, index) => (
                                    <WrapItem
                                        cursor="pointer"
                                        key={index}
                                        transition="300ms ease-in-out"
                                        _hover={{ fontWeight: "600" }}
                                        onClick={() => {
                                            if (index === 0) {
                                                history.push("/");
                                                const element = document.getElementById("aboutPage");
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 0 : 0,
                                                    behavior: "smooth",
                                                });
                                            } else if (index === 1) {
                                                history.push("/");
                                                const element = document.getElementById("contactPage");
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 50 : 0,
                                                    behavior: "smooth",
                                                });
                                            } else if (index === 2) {
                                                history.push("/privacy-policy");
                                            } else if (index === 3) {
                                                history.push("/aml-policy");
                                            } else if (index === 4) {
                                                history.push("/terms-and-conditions");
                                            }
                                        }}
                                    >
                                        {item}
                                    </WrapItem>
                                ))}
                            </Wrap>
                            <Text align="end" as="b" fontSize={["15px", "16px"]}>
                                {lang === "fr"
                                    ? "Un produit de Zine Fintech Limited."
                                    : "A Product of Zine Fintech Limited"}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
