import { Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

export default function InputHolder({ type, inpRef, label, ...props }) {
    const [show, setShow] = useState(false)
    return (
        <Flex w="100%" fontSize="16px" mb="6" direction="column">
            {
                type === "password" ?
                <Flex w="100%" position="relative" align="center">
                    <input ref={inpRef} style={{ width: "100%", padding: "10px 44px 10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px" }} type={show ? "text" : "password"} {...props} />

                    <Text position="absolute" py="2px" px="2px" cursor="pointer" right="12px" fontSize="20px" color="#667085" onClick={() => show ? setShow(false) : setShow(true)}><i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i></Text>
                </Flex>
                :
                type === "textarea" ?
                <textarea ref={inpRef} style={{ width: "100%", padding: "10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px", resize: "none", height: "167px" }} type={type} {...props}></textarea>
                :
                <input ref={inpRef} style={{ width: "100%", padding: "10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px" }} type={type} {...props} />
            }
        </Flex>
    )
}