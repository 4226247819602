import Markdown from "react-markdown";
import {  Flex } from "@chakra-ui/react";

import styles from "../styles/markdown.module.css";
import NavBar from "../components/navBar";

const MarkdownRenderer = ({ content, lang, setLang }) => {
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" id="nav" position="fixed" top="0" left="0" zIndex="1" direction="column">
                <NavBar page lang={lang} setLang={setLang} />
            </Flex>

            <Markdown
                components={{
                    p: (prop) => {
                        if (prop.children.length === 1 && prop.children[0].type === "strong") {
                            return <h2>{prop.children[0].props.children}</h2>;
                        }

                        return <p>{prop.children}</p>;
                    },
                }}
                className={styles.reactMarkDown}
            >
                {content}
            </Markdown>
        </Flex>
    );
};

export default MarkdownRenderer;
