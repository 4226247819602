import axios from "axios";
import Toast from "../components/notify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export async function AuthConnect(type, url, data) {
    //AXIOS CLIENT HEADER
    const authToken = sessionStorage.getItem("ZineToken");
    const client = axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            accesstoken: authToken,
        },
    });

    //CONNECT FUNCTION
    try {
        const response =
            type === "post"
                ? await client.post(url, data)
                : type === "delete"
                ? await client.delete(url)
                : await client.get(url);
        if (response.data.success === true) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 401) {
            Toast("Hello your session has timed out, Kindly login again", "error");
            setTimeout(() => {
                sessionStorage.clear();
                window.location.replace("/");
            }, 3000);
        } else if (error?.response?.status) {
            Toast(error?.response?.data?.message, "error");
        } else {
            Toast("Cannot connect at the moment, please try again", "error");
        }
    }
}

export const requireAuth = (Component) => {
    return (props) => {
        const auth = sessionStorage.getItem("ZineAuth");
        const history = useHistory();

        useEffect(() => {
            if (auth === null) {
                history.replace("/");
            }
        }, []);

        return <Component {...props} />;
    };
};

export async function CoinMarketCap(type, url, data) {
    const client = axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });
    try {
        const response = type === "post" ? await client.post(url, data) : await client.get(url);
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        // if (error.response?.status) {
        //     Toast(error.response.statusText, "error");
        // } else {
        //     Toast("Cannot connect at the moment, please try again", "error");
        // }
    }
}
