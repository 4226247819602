import React, { useEffect } from "react";

export default function TradingViewWidget({ coin }) {
    useEffect(() => {
        const script = document.createElement("script");
        const scriptText = document.createTextNode(`{
      "symbols": ["BTCUSD", "ETHUSD", "BNBUSD", "SOLUSD", "USDTUSD"],
      "chartOnly": false,
      "isTransparent": true,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "colorTheme": "light",
      "autosize": false,
      "showVolume": false,
      "showMA": false,
      "hideDateRanges": false,
      "hideMarketStatus": true,
      "hideSymbolLogo": false,
      "scalePosition": "right",
      "scaleMode": "Normal",
      "noTimeScale": false,
      "valuesTracking": "1",
      "changeMode": "price-and-percent",
      "chartType": "area",
      "maLineColor": "#2962FF",
      "maLineWidth": 1,
      "maLength": 9,
      "lineWidth": 2,
      "lineType": 0,
      "gridLineColor": "rgba(120, 123, 134, 0.45)",
      "fontColor": "#101828",
      "enabled_features": ["horz_touch_drag_scroll", "vert_touch_drag_scroll", "show_zoom_and_move_buttons_on_touch", "pinch_scale"],
      "dateRanges": [
        "1m|30",
        "3m|60",
        "12m|1D",
        "60m|1W",
        "all|1M"
      ]
    }`);
        script.type = "text/javascript";
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.async = true;
        script.appendChild(scriptText);
        document.getElementById("mainViewArea").appendChild(script);
    }, []);

    return (
        <div
            className="tradingview-widget-container"
            id="mainViewArea"
            style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
        >
            <div
                className="tradingview-widget-container__widget"
                style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
            ></div>
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/" rel="noopener noreferrer nofollow" target="_blank">
                    <span style={{ color: "#667085", fontSize: "13px", marginTop: "20px" }}>Charts by TradingView</span>
                </a>
            </div>
        </div>
    );
}
