import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/global.css";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import Landing from "./landing";

import Footer from "../components/footer";
import ScrollToTop from "../components/sc";
import Privacy from "./privacy-policy";
import AMLPolicy from "./aml-policy";
import TermsAndCondition from "./terms-and-condition";
import { useState } from "react";

function App() {
    const [lang, setLang] = useState(() => {
        return localStorage.getItem("isLangSet") ?? "en";
    });

    return (
        <ChakraProvider>
            <Flex direction="column" fontSize="16px" color="#101828">
                <Router>
                    <ScrollToTop>
                        <Flex w="100%" direction="column">
                            <Switch>
                                <Route
                                    path="/terms-and-conditions"
                                    component={() => <TermsAndCondition lang={lang} setLang={setLang} />}
                                />
                                <Route
                                    path="/privacy-policy"
                                    component={() => <Privacy lang={lang} setLang={setLang} />}
                                />
                                <Route
                                    path="/aml-policy"
                                    component={() => <AMLPolicy lang={lang} setLang={setLang} />}
                                />
                                <Route exact path="/" component={() => <Landing lang={lang} setLang={setLang} />} />
                            </Switch>

                            <Flex w="100%">
                                <Footer lang={lang} />
                            </Flex>
                        </Flex>
                    </ScrollToTop>
                </Router>
            </Flex>
        </ChakraProvider>
    );
}

export default App;
