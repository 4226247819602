import React, { useEffect } from "react";

export default function TradingViewWidget2({ coin, coinIni }) {
    useEffect(() => {
        const script = document.createElement("script");
        const scriptText = document.createTextNode(
            `{
      "symbols": ["` +
                coin +
                `USD"],
      "chartOnly": true,
      "isTransparent": true,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "colorTheme": "light",
      "autosize": false,
      "showVolume": false,
      "showMA": false,
      "hideDateRanges": true,
      "hideMarketStatus": true,
      "hideSymbolLogo": false,
      "scalePosition": "right",
      "scaleMode": "Normal",
      "noTimeScale": false,
      "valuesTracking": "1",
      "changeMode": "price-and-percent",
      "chartType": "area",
      "maLineColor": "#2962FF",
      "maLineWidth": 1,
      "maLength": 9,
      "lineWidth": 2,
      "lineType": 0,
      "gridLineColor": "rgba(120, 123, 134, 0.45)",
      "fontColor": "#101828",
      "dateRanges": [
        "1d|1"
      ]
    }`
        );
        script.type = "text/javascript";
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.async = true;
        script.appendChild(scriptText);
        script.id = "terererrer";
        if (coinIni !== "") {
            const ele = document.getElementById("mainViewArea2");
            const remEle = document.getElementById("terererrer");
            ele.removeChild(remEle);
        }
        document.getElementById("mainViewArea2").appendChild(script);
    }, [coin]);

    return (
        <div
            className="tradingview-widget-container"
            id="mainViewArea2"
            style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
        >
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/" rel="noopener nofollow noreferrer" target="_blank">
                    <span style={{ color: "#667085", fontSize: "13px", marginTop: "20px" }}>Charts by TradingView</span>
                </a>
            </div>
            <div
                className="tradingview-widget-container__widget"
                style={{ height: "100%", width: "100%", backgroundColor: "transparent" }}
            ></div>
        </div>
    );
}
